<div *ngIf="data" class="laptop_padding w_100 single_page_container mt_35">
  <ng-container >
    <div class="navigate_and_share_container flex_between_align_center font_15 bold_600" *ngIf="!isMobile">
      <div>
        <app-navigation
          [navigationData]="navigationData"></app-navigation>
      </div>
      <div class="share_and_like_container">
        <div class="flex_center_align_center pointer" (click)="copyPathForShare()">
          <img src="assets/images/icons/product_single/share.svg">
          <span class="ml_8">
        {{'product.single_page.share' | translate}}
      </span>
        </div>
        <div class="flex_center_align_center pointer">
          <app-add-to-favorites-button
            [id]="data.id"
            [type]="addToFavoriteTypeEnum.PRODUCT"
            [liked]="data.liked"
            (favoriteChangeEventEmitter)="data.liked = $event"
          >
          </app-add-to-favorites-button>
          <span class="ml_8">
        {{'product.single_page.add_to_favorites' | translate}}
      </span>
        </div>
      </div>
    </div>
    <div class="images_of_flower laptop_margin position_relative" *ngIf="isMobile">
      <app-custom-slider *ngIf="data?.resourceUrls">
        <div *ngFor="let item of data.resourceUrls;let i = index" class="h_100 items pointer">
          <div class="card_container position_relative flex_column br_20 flow_hide"
               [ngClass]="{'full_width':data.resourceUrls.length === 1}">
            <img [src]="item.resourceUrl">
          </div>
        </div>
      </app-custom-slider>
    </div>
  </ng-container>
  <div class="single_product_block">
    <div class="flower_details">
      <div class="flower_image flex w_100" *ngIf="!isMobile">
        <app-vertical-swiper *ngIf="isBrowser"
          [data]="data.resourceUrls"
        >
        </app-vertical-swiper>
        <div class="flower_slider_container">
          <app-product-gallery
            [img_data]="data.resourceUrls"
            [type]="swiperTypeEnum"
          >
          </app-product-gallery>
        </div>
      </div>
      <div class="flower_info w_100">
<!--        <h1 style="visibility: hidden" *ngIf="!isBrowser">{{data.title}}</h1>-->

        <ng-container>
          <div class="title_and_rate text_title_middle bold_800" [ngClass]="{'pl_pr_16':isMobile}">
            <h1 class="mb_12 name">{{data.title}}</h1>
            <app-star-rate [rate]="data.productAverageRate"
                           [rateCount]="data.rateCount"
            ></app-star-rate>
          </div>
          <div class="description_container" [ngClass]="{'pl_pr_16':isMobile}" >
            <div class="mb_20">
          <span class="font_13 bold_600 text_gray_color">
            {{'product.single_page.product_description' | translate}}
          </span>
              <div class="mt_8 font_15 bold font_color">
                {{data.description || ''}}
              </div>
            </div>
            <div class="composition_of_the_bouquet mb_20">
            <span class="font_13 bold_600 text_gray_color">
              {{'product.single_page.compound' | translate}}:
            </span>
              <div class="mt_8">
              <span class="font_15 bold font_color" *ngFor="let flower of data.composition;let i =index">
                {{(flower?.name || '') | titlecase}} - {{flower?.count || ''}} шт
                {{i === data.composition.length - 1 ? '' : ','}}
              </span>
              </div>
            </div>
            <div class="dimensions" *ngIf="isMobile">
              <span class="font_13 bold_600 text_gray_color">
                {{'product.single_page.dimensions' | translate}}:
              </span>
              <div class="font_15 bold font_color mt_8">
              <span *ngIf="data.height">
                {{'product.single_page.width' | translate}} - {{data.width || ''}} см.
              </span>
                <span *ngIf="data.height">
               , {{'product.single_page.height' | translate}} - {{data.height || ''}} см.
              </span>
              </div>
            </div>

          </div>
          <div class="price_and_buy_container flex_align_center" *ngIf="!isMobile">
          <span class="text_title_big bold_800 price_discounted font_color">
            {{(data.price | priceSpaces) || ''}} ₽
          </span>
            <div class="bonus_container text_title bold_800 ml_12" *ngIf="data.bonusAmount">
              +{{data.bonusAmount}}
            </div>
          </div>
          <div class="button_buy_or_go_to_cart"
               [ngStyle]="{
             'grid-template-columns':data.countInBasket && !isMobile ? '1fr 148px' : '1fr'
             }"
          >
            <button class="main_buttons w_100 flex_center_align_center  "
                    [ngClass]="[isMobile ? 'black_btn with_padding bold_600 br_12':'main_red_btn bold_700 br_24']"
                    (click)="data.countInBasket ? goToCart() : addToBasket($event)"
            >
              {{data.countInBasket ? ('product.single_page.go_to_cart' | translate) : ('product.single_page.add_to_basket' | translate)}}
              <div class="flex_start" *ngIf="isMobile">
                <span class="bold_800"> - {{(data.price | priceSpaces) || ''}} ₽</span>
                <app-bonus-display [nextTo]="true"
                                   [bonus]="data.bonusAmount"></app-bonus-display>
              </div>
            </button>

            <app-add-and-reduce *ngIf="data.countInBasket && !isMobile"
                                [productCard]="data"
                                [type]="addAndReduceTypeEnums.SINGLE_PAGE">
            </app-add-and-reduce>
          </div>

          <div class="shop_info_container w_100">
            <div class="shop_view flex_between_align_center pointer" (click)="filterProductsByShop()">
              <div class="shop_info flex_align_center">
                <div class="shop_logo" [ngClass]="{'default_image flex_center_align_center':!data.shop.imageUrl}">
                  <img [src]="data.shop.imageUrl || 'assets/images/icons/shops/shop-default.svg'"
                       [ngClass]="{'logo_img':data.shop.imageUrl}">
                </div>
                <div class="flex_column ml_12">
              <span class="text_normal_sub bold_700">
                {{data.shop.shopName}}
              </span>
                  <span class="font_15 bold_600 text_gray_color"
                        *ngIf="(data.shop.averageDeliveryTime | millisecondsToMinutes : data.shop.interval ) as delivery">
               ~{{delivery}} мин.
              </span>
                </div>
              </div>
              <app-add-to-favorites-button
                [id]="data.shop.id"
                [classTransparent]="isMobile"
                [type]="addToFavoriteTypeEnum.SHOP"
                [liked]="data.shop.liked"
                (favoriteChangeEventEmitter)="data.shop.liked = $event"
              >
              </app-add-to-favorites-button>
            </div>
            <div class="rate_and_delivery_container flex_between_align_center">
              <app-star-rate [rate]="data.shop.shopAverageRate"
                             [rateCount]="data.shop.rateCount"
              ></app-star-rate>
              <div *ngIf="data.minimalDeliveryPrice">
                <span class="font_15 bold text_gray_color">{{'product.single_page.min_delivery_price' | translate}}</span>
                <span class="font_15 bold_800">
              {{data.minimalDeliveryPrice}} ₽
            </span>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="pl_16" *ngIf="isBrowser">
      <app-popular-products
        [productId]="data.id"
        [componentType]="type"
        [isMobile]="isMobile"
      >
      </app-popular-products>
    </div>
  </div>
</div>


