import {Injectable} from "@angular/core";
import * as StompJs from "@stomp/stompjs";
import * as SockJs from "sockjs-client"
import {environment} from "../../../environments/environment";
import {AuthService} from "@services/auth/auth.service";
import {Subject} from "rxjs";

@Injectable({
  providedIn: null
})
export class SocketService {
  webSocket!: any;
  tryToReconnectCount = 0;
  currentChatId!: number;
  subjectForMessaging = new Subject<any>();

  constructor(
    private authService: AuthService,
  ) {}

  startConversation(chatId: number): void {
    this.currentChatId = chatId;
    this.tryToReconnectCount = 0;
    this.connectWebSocket();
  }

  connectWebSocket(): void {
    if (this.tryToReconnectCount > 3) {
      return;
    }
    this.tryToReconnectCount++;
    // if (environment.production) {
    //   console.log = function() {};
    // }
    if (!this.authService.getUser()) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${this.authService?.getUser()?.accessToken}`,
      chatId: this.currentChatId
    };
    this.webSocket = StompJs.Stomp.over(
      new SockJs(`https://${environment.socketUrl}/socket.io`)
    );
    this.connectAndReconnect( headers);
  }

  connectAndReconnect(headers: any): void {
    this.webSocket.connect(headers, () => {
      this.tryToReconnectCount = 0;
      this.subscribeToMessages();
    },(e: any) => {
      setTimeout(() => {
        this.connectWebSocket();
      },5000)
    });
  }

  private subscribeToMessages(): void {
    this.webSocket.subscribe(`/user/${this.authService?.getUser()?.userId}-${this.currentChatId}/user-chat-messages`, (message: any) => {
      const body = JSON.parse(message.body);
      console.log(body);
      if (!body) {return; }
      this.subjectForMessaging.next(body)
    });
    this.webSocket.subscribe(`/user/${this.authService?.getUser()?.userId}-${this.currentChatId}/user-seen-messages`, (message: any) => {
      const body = JSON.parse(message.body);
      console.log(body);
      if (!body) {return; }
      this.subjectForMessaging.next(body)
    });
  }

  disconnectSocket(force = true): void {
    this.webSocket?.disconnect();
    this.webSocket = null;
    if (force) {
      this.subjectForMessaging.complete()
    }
  }

}
