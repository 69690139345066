<div *ngIf="resources?.length" class="pointer resources_block"
     [ngClass]="{'two_img_state': hasTwoImages,'four_pictures' : hasFourImages, 'has_odd_length': hasOddLength, 'has_even_length' : hasEvenLength}">
  <div  *ngFor="let res of resources" (click)="openImgNewBlank(res.resourceUrl)" class="chat-resource">
    <img *ngIf="res.resourceType === 'IMAGE'"  [src]="res.resourceUrl" alt="Uploaded Image">
    <video *ngIf="res.resourceType === 'VIDEO'" controls>
      <source [src]="res.resourceUrl" type="video/mp4">
      Ваш браузер не поддерживает видео.
    </video>
  </div>
</div>
