import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

//Services
import {AuthService} from "@services/auth/auth.service";
import {CityService} from "@services/city/city.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";

//Constants
import {CheckToken} from "@interfaces/auth/login.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {ProductPaginationInterface} from "@interfaces/catalog/products.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {CityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, TranslateModule]
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  activeRoute: string;
  pages = [
    {
      isActive: false,
      route: '/products',
      icon: 'catalog',
      title: 'navbar.catalog',
      badge: {
        count: 0,
      }
    },
    {
      isActive: false,
      route: '/favorites',
      icon: 'favorites',
      title: 'navbar.favorites',
      badge: {
         name: 'totalFavoriteElements',
         count: 0,
      }
    },
    {
      isActive: false,
      route: '/basket',
      icon: 'basket',
      title: 'navbar.basket.title',
      badge: {
        count: 0,
      }
    },
    {
      isActive: false,
      route: '/orders',
      icon: 'orders',
      title: 'navbar.orders',
      badge: {
        count: 0,
      }
    },
    {
      isActive: false,
      route: '/my-account',
      icon: 'my-account',
      title: 'my_account.profile',
      badge: {
        name: 'messages-count',
        count: 0,
      }
    }
  ]
  isUser!: CheckToken;
  totalFavoriteElements!: number;
  subscriptionForUpdateFavorites!: Subscription;
  city!: { address: FavoriteAddressInterface } | { city: CityInterface };

  constructor(private router: Router,
              private authService: AuthService,
              private requestService: RequestMethodsService,
              private globalSubscriptionService: GlobalSubscriptionService,
              private cityService: CityService
  ) {
    this.subscribeForFavoritesChange();
    this.getFavorites()
    this.activeRoute = '';
    this.isUser = this.authService.checkToken();

    this.getDataFromLocal();
    if (!this.isUser) {
      return;
    }
    this.globalSubscriptionService.getMessage().subscribe((res: {type: string, message: any}) => {
      if (res.type === 'badges' && res.message) {
        this.pages.forEach(page => {
          if (page.badge?.name === 'messages-count') {
              page.badge.count = res.message.data?.unreadChatsCount;
          }
        })
      } else if (res.type === 'updateUnreadMessagesCount') {
        this.getUnreadMessagesCount()
      }
    })

    this.getUnreadMessagesCount()
    console.log(this.totalFavoriteElements)
  }

  getDataFromLocal(): void {
    const modelCityOrAddress = this.cityService.getCityModelFromLocalStorage()
    if (modelCityOrAddress) {
      this.city = modelCityOrAddress
    }
  }


  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.pages.forEach((page => page.isActive = (url === page.route)))
      }
    });
    this.getFavorites();
  }

  getUnreadMessagesCount(): void {
    if (this.authService.getTokenFromLocalStorage()?.accessToken) {
      this.pages.forEach(page => {
        if (page.badge?.name === 'messages-count') {
          this.authService.getBadgesMessages().subscribe(res => {
            page.badge.count = res.count;
          })
        }
        // TODO
      })
    }
  }

  navigateTo(url: string): void {
    if (this.isUser || url === '/products') {
      this.router.navigate([url]);
    } else {
      this.openSignInDialog();
    }
  }

  openSignInDialog(): void {
    if (this.authService.checkTokenAndRedirectToAuth()) {
      return;
    }
  }

  subscribeForFavoritesChange(): void {
    this.subscriptionForUpdateFavorites = this.globalSubscriptionService.getMessage().subscribe((data: { type: string }) => {
      if ([UpdateSubscriptionTypesEnum.UPDATE_FAVORITES, UpdateSubscriptionTypesEnum.CITY_SELECTION].includes(data.type as UpdateSubscriptionTypesEnum)) {
        this.getDataFromLocal();
        this.getFavorites();
      }
    })
  }

  getFavorites(): void {
    if (!this.city || !this.isUser) {
      return;
    }
    let cityId, longitude, latitude;
    if ('city' in this.city) {
      cityId = this.city.city.id
    } else {
      [longitude, latitude] = [this.city.address?.longitude, this.city.address?.latitude]
    }
    this.requestService.post(`${EndpointConstant.products}/${EndpointConstant.favorites}/${EndpointConstant.filter}?page=1&size=10`,
      {filter: {cityId, latitude, longitude}}
    ).subscribe((response: ProductPaginationInterface) => {
      this.totalFavoriteElements = response.totalElements
    }, (error) => console.log(error))
  }

  ngOnDestroy(): void {
    this.subscriptionForUpdateFavorites.unsubscribe();
  }
}
