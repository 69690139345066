import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthModule} from "./modules/auth/auth.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, Injector, NgModule} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  withFetch,
  provideHttpClient,
  withInterceptorsFromDi
} from "@angular/common/http";
//components
import {AppComponent} from './app.component';
import {NavbarComponent} from "./partials/components/navbar/navbar.component";
import {TopNavbarComponent} from './partials/components/navbar/top-navbar/top-navbar.component';
import {FooterComponent} from './partials/components/footer/footer.component';
import {CityDropDownComponent} from './partials/components/navbar/top-navbar/city-drop-down/city-drop-down.component';
import {MobileNavbarComponent} from './partials/components/mobile-navbar/mobile-navbar.component';
import {MobileMenuComponent} from './partials/components/mobile-menu/mobile-menu.component';
//services
import {RequestInterceptorService} from "@services/request/interceptors/request-interceptors.service";
//material components
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
// Modules
import {GlobalModule} from "./partials/shared-modules/global/global.module";
import {ToastrModule} from "ngx-toastr";
import {MatMenuModule} from "@angular/material/menu";
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {ProductModule} from "./partials/shared-modules/product/product.module";
import {
  DeliveryAddressComponent
} from './partials/components/navbar/top-navbar/delivery-address/delivery-address.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from "@angular/material/radio";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {environment} from "../environments/environment";
import {AppInfoComponent} from './partials/components/app-info/app-info.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {LOCATION_INITIALIZED} from "@angular/common";
import {REQUEST, SsrCookieService} from "ngx-cookie-service-ssr";
import {OpenChatButtonComponent} from "./partials/shared-modules/global/open-chat-button/open-chat-button.component";
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {MessagingService} from "@services/firebase/messaging.service";
import { initializeApp } from "firebase/app";

initializeApp(environment.firebaseConfig);

@NgModule({ schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatChipsModule,
        MatSliderModule,
        MatDialogModule,
        MatSelectModule,
        ProductModule,
        AuthModule,
        ToastrModule.forRoot(),
        MatMenuModule,
        TranslateModule.forRoot({
            defaultLanguage: 'ru',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GlobalModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatRadioModule,
        NavbarComponent,
        TopNavbarComponent,
        FooterComponent,
        CityDropDownComponent,
        MobileNavbarComponent,
        MobileMenuComponent,
        DeliveryAddressComponent,
        AppInfoComponent,
        OpenChatButtonComponent,
        AngularFireMessagingModule
    ], providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    // Handle all requests in all project, detect and show error formMessages, add Authorization token and etc...
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi(),withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: REQUEST,
      useFactory: () => {
        if (typeof window !== 'undefined') {
          return {};
        } else {
          return (global as any).request;
        }
      },
    },
    MessagingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig }
  ] })
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'ru'
      translate.setDefaultLang('ru');
      translate.use(langToSet).subscribe(() => {
      }, err => {
      }, () => {
        resolve(null);
      });
    });
  });
}
