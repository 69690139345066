import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
// Constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {v4} from "uuid";


@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    firebaseTokeId = 'FirebaseTokeId'

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private requestService: RequestMethodsService,
    ) {}

    requestPermission() {
        this.angularFireMessaging.requestPermission.subscribe((permission) => {
          console.log(permission)
            if (permission === 'denied') { return; }
            this.sendToken()
        })
    }

    sendToken() {
        this.angularFireMessaging.requestToken?.subscribe((token) => {
            if (!token) { return; }
            const data = localStorage.getItem(this.firebaseTokeId);
            let deviceId;
            if (data) {
                deviceId = JSON.parse(data)?.deviceId;
            } else {
                deviceId = v4();
                localStorage.setItem(this.firebaseTokeId, JSON.stringify({deviceId}))
            }
            this.requestService.post(EndpointConstant.firebaseToken, {
                fcmToken: token,
                deviceId
            }).subscribe((res) => {
              console.log(res)
                this.receiveMessage();
            })
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((msg: any) => {
          console.log(msg)
            this.currentMessage.next(msg);
        })
    }

    deleteToken(): void {
        const data = localStorage.getItem(this.firebaseTokeId);
        if (!data) { return;}
        this.angularFireMessaging.deleteToken(JSON.parse(JSON.stringify(data)).deviceId).subscribe()
    }

    run(): BehaviorSubject<any> {
        this.requestPermission()
        return this.currentMessage;
    }

}
