import {afterNextRender, Component} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {AuthService} from "@services/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ChatComponent} from "../chat/chat.component";
import {BrowserService} from "@services/components/browser.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {type} from "node:os";


@Component({
  selector: 'app-open-chat-button',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './open-chat-button.component.html',
  styleUrl: './open-chat-button.component.scss'
})
export class OpenChatButtonComponent {
  accessToken: string | undefined;
  isMobile:boolean = false;
  badgesCount: number = 0;

  constructor(
    private authService: AuthService,
    protected dialog: MatDialog,
    private browserService: BrowserService,
    private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    afterNextRender(() => {
      this.accessToken = this.authService.getTokenFromLocalStorage()?.accessToken;
      this.isMobile = this.browserService.isMobileDevice;
      if (this.accessToken && !this.isMobile) {
        this.authService.getBadgesMessages().subscribe(res => {
          this.badgesCount = res.count
        })
      }
      this.globalSubscriptionService.getMessage().subscribe((res: {type: string, message: any}) => {
        if (res.type === 'badges' && res.message) {
          this.badgesCount = res.message.data.unreadChatsCount
        }
      })
    })
  }

  openChat(): void {
    this.dialog.open(ChatComponent, {
      maxWidth: "994px",
      height: "95vh"
    }).afterClosed().subscribe(() => {
      this.authService.getBadgesMessages().subscribe(res => {
        this.badgesCount = res.count
      })
    })
  }

}
