<div class="card_container position_relative flex_column br_16"
     [class.from_popular]="type === productCardEnum.POPULAR"
>
  <!--  <div *ngIf="productCard.resourceUrls.length > 1">-->
  <!--    <app-product-gallery-->
  <!--      [img_data]="productCard.resourceUrls"-->
  <!--      [numOfColumns]="numOfColumns"-->
  <!--      [type]="swiperTypeEnum"-->
  <!--      (navigateToSinglePageEmitter)="getSingleProductInfo()"-->
  <!--    ></app-product-gallery>-->
  <!--  </div>-->
  <div class="product_card_image_container flow_hide br_32 pointer flex_center_align_center"
       (click)="getSingleProductInfo()">
    <img [src]=productCard.resourceUrls[0].resourceUrl>
    <div class="free_delivery" *ngIf="productCard.deliveryPrice === 0">
      {{'product.freeDeliveryForCards' | translate}}
    </div>
  </div>
  <div class="info flex_column_between">
    <span class="product_price bold_800 text_title_middle">{{productCard.price | priceSpaces}} ₽</span>
    <span class="title_description mt_8 bold_600 font_15">{{productCard.title}}</span>
    <app-star-rate [rate]="productCard.averageRate"
                   [rateCount]="productCard.rateCount"
    >
    </app-star-rate>
    <div class="shop_info mt_18" *ngIf="productCard.shop">
      <div class="shop_logo_and_title flex_align_center pointer" (click)="goToShopDetailsPage()">
        <div class="shop_logo">
          <img [src]="productCard.shop.imageUrl">
        </div>
        <span class="shop_title bold_700 ml_8">
        {{productCard.shop.shopName}}
      </span>
      </div>
      <div class="delivery font_15 bold"
           *ngIf="(productCard.deliveryTime | millisecondsToMinutes) as delivery">
        {{'product.catalog.delivery' | translate}} {{delivery | priceSpaces}} мин,
        <span [ngClass]="{green_text : productCard.deliveryPrice === 0}">
          {{productCard.deliveryPrice + '₽'}}
        </span>
      </div>
    </div>

<!--    <div class="buy" [ngClass]="[type !== productCardEnum.POPULAR ? 'mt_16' : 'mt_30']">-->
<!--      <button class="add_to_basket_btn main_buttons pointer bold_700 flex_center_align_center br_32"-->
<!--              *ngIf="!productCard.countInBasket; else numberOfBouquets"-->
<!--              (click)="addToBasket($event)">-->
<!--        <img src="assets/images/icons/orange_plus.svg">-->
<!--      </button>-->
<!--      <ng-template #numberOfBouquets>-->
<!--        <app-add-and-reduce [productCard]="productCard" [type]="addAndReduceTypeEnums.GENERAL">-->
<!--        </app-add-and-reduce>-->
<!--      </ng-template>-->
<!--    </div>-->
  </div>

  <app-add-to-favorites-button
    [id]="productCard.id"
    [classTransparent]="true"
    [type]="addToFavoriteTypeEnum.PRODUCT"
    [liked]="productCard.liked"
    (favoriteChangeEventEmitter)="disLike($event)"
  >
  </app-add-to-favorites-button>

  <app-bonus-display [bonus]="productCard.bonusAmount"></app-bonus-display>
</div>
