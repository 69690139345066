import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//pipes
import {MakePaginationCountArrayPipe} from "./arrays-methods/make-pagination-count-array.pipe";
import {ToLocalDatePipe} from "./date-methodes/to-local-date.pipe";
import {SearchInArrayPipe} from "./search-in-array/search-in-array.pipe";
import {FilterConditionPipe} from "./filter-condition/filter-confition.pipe";
import {SecondsToHoursPipe} from "./date-methodes/seconds-to-hours.pipe";
import {GetValueByKeyPipe} from "./object-methods/get-value-by-key.pipe";
import {AddDiapasonToDatePipe} from "./date-methodes/add-diapason-to-date.pipe";
import {MillisecondsToMinutesPipe} from "./date-methodes/milliseconds-to-minutes.pipe";

const pipes = [
  MakePaginationCountArrayPipe,
  ToLocalDatePipe,
  SearchInArrayPipe,
  FilterConditionPipe,
  SecondsToHoursPipe,
  GetValueByKeyPipe,
  AddDiapasonToDatePipe,
  MillisecondsToMinutesPipe,
];

@NgModule({
    imports: [
        CommonModule,
        ...pipes
    ],
    exports: pipes
})
export class PipesModule {
}

