import {Component, ElementRef, EventEmitter, Input, LOCALE_ID, Output, ViewChild} from '@angular/core';
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, registerLocaleData} from "@angular/common";
import {tap} from "rxjs";
import {ToLocalDatePipe} from "../../../../pipes/date-methodes/to-local-date.pipe";
import {Router} from "@angular/router";
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
// Constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {ChatInterface, ChatDto} from "@interfaces/chat/chat.interfaces";
import {LoadingComponent} from "../../loading/loading.component";
import {BrowserService} from "@services/components/browser.service";
//Date
import localeRu from '@angular/common/locales/ru';
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
registerLocaleData(localeRu);

@Component({
  selector: ' app-messages',
  standalone: true,
  providers:[{ provide: LOCALE_ID, useValue: 'ru' }],
  imports: [
    MatTab,
    TranslateModule,
    MatTabGroup,
    CommonModule,
    ToLocalDatePipe,
    LoadingComponent,
  ],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent {
  @ViewChild('messagesDiv') messagesDiv!: ElementRef;
  @ViewChild('archiveMessagesDiv') archiveMessagesDiv!: ElementRef;
  @Output() chatId = new EventEmitter<number>();
  @Output() updateConversation = new EventEmitter<void>();
  @Input()  set activeChat(id: any) {
    if (!id) return;
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.messages.forEach((message: ChatDto) => {
        if (message.id === id) {
          message.unreadMessagesCount = 0;
        }
      })
    },500)
    this.selectedMessages = id;
  };
  chat!: ChatInterface;
  messages: Array<ChatDto> = [];
  archiveMessages: Array<ChatDto> = [];
  selectedIndex = 0;
  page = 1;
  scrollTop = 0;
  totalPages = 1;
  loading = false;
  emptyPage = false;
  selectedMessages: number | null = null;
  isMobile = false;

  constructor(
    private requestService: RequestMethodsService,
    private browserService: BrowserService,
    private router: Router,
    private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    this.getActiveMessages()
    this.isMobile = this.browserService.isMobileDevice
    this.globalSubscriptionService.getMessage().subscribe((res: {type: string, message: any}) => {
      if (res.type === 'badges' && res.message) {
        console.log(res.message)
         // = res.message.data.unreadChatsCount
      }
    })
  }

  tabChange(): void {
    this.page = 1;
    this.scrollTop = 0;
    this.totalPages = 1;
    this.loading = false;
    this.emptyPage = false;
    this.selectedMessages = null;
    this.updateConversation.emit()
    if (this.selectedIndex === 0) {
      this.messages = [];
      this.getActiveMessages();
    } else if (this.selectedIndex === 1) {
      this.archiveMessages = [];
      this.getArchiveMessages()
    }
  }

  getActiveMessages(loading = true): void {
    if (this.loading || (this.page > this.totalPages)) { return; }

    this.loading = loading;

    this.requestService.get(
      `${EndpointConstant.user}/${EndpointConstant.chat}/${EndpointConstant.active}?page=${this.page}&size=20`
    ).pipe(tap(() => this.loading = false))
      .subscribe((data: ChatInterface) => {
        this.page++;
        this.chat = data
        // this.emptyPage = !(!!data.content?.length);
        this.totalPages = data.totalPages;
        console.log(this.chat)
        // if (this.emptyPage) { return; }
        this.messages = [...this.messages , ...data.content];


        setTimeout(() => {
          if (this.scrollTop) {
            this.archiveMessagesDiv.nativeElement.scrollTop = this.scrollTop;
          }
        }, 150)

      },() => {
        this.loading = false;
      })
  }

  onMessagesSectionScroll(event: any): void {
    if (!event.target) { return; }
    const element = event.target;
    const pos = element.scrollTop + element.offsetHeight;
    const max = element.scrollHeight || 0;

    if ((pos >= max) && (this.chat.content.length / (10 * (this.page - 1))) >= 1) {
      // if (this.loading) { return; }
      this.scrollTop = element.scrollTop;
      if (this.selectedIndex === 0) {
        this.getActiveMessages(false);
      } else {
        this.getArchiveMessages(false);
      }
    }
  }

  openChat(message: ChatDto): void {
    if (this.isMobile) {
      this.router.navigate([`/conversation/${message.id}`]);
    } else {
      message.unreadMessagesCount = 0;
      this.selectedMessages = message.id;
      this.chatId.emit(message.id)
    }
    this.activeChat = null;
  }

  getArchiveMessages(loading = true): void {
    if (this.loading || (this.page > this.totalPages)) { return; }

    this.loading = loading;

    this.requestService.get(
      `${EndpointConstant.user}/${EndpointConstant.chat}/${EndpointConstant.archive}?page=${this.page}&size=20`
    ).pipe(tap(() => this.loading = false))
      .subscribe((data: ChatInterface) => {
        this.page++;
        this.emptyPage = !(!!data.content?.length);
        this.totalPages = data.totalPages;

        if (this.emptyPage) { return; }
        this.archiveMessages = [...this.archiveMessages , ...data.content];


        setTimeout(() => {
          if (this.scrollTop) {
            this.messagesDiv.nativeElement.scrollTop = this.scrollTop;
          }
        }, 150)

      }, () => {
        this.loading = false;
      })
  }

}
