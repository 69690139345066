import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ToLocalDatePipe} from "../../../../pipes/date-methodes/to-local-date.pipe";
import {GlobalModule} from "../../global.module";
import {Subscription, tap} from "rxjs";
import {LoadingComponent} from "../../loading/loading.component";
import {ActivatedRoute} from "@angular/router";
import {ChangeDateSeparatorFormatPipe} from "../../../../pipes/chat/change-date-separator-format.pipe";
import {ResourcesComponent} from "./resources/resources.component";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
// Services
import {AuthService} from "@services/auth/auth.service";
import {SocketService} from "@services/socket/socket.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {BrowserService} from "@services/components/browser.service";
// Constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {ChatDto, MessagesInterface, MessagesPaginationInterface} from "@interfaces/chat/chat.interfaces";
import {UsersInterface} from "@interfaces/components/users.interface";

@Component({
  selector: 'app-conversation',
  standalone: true,
  imports: [
    CommonModule,
    ToLocalDatePipe,
    ReactiveFormsModule,
    GlobalModule,
    LoadingComponent,
    ChangeDateSeparatorFormatPipe,
    ResourcesComponent,
    CdkTextareaAutosize,
  ],
  providers: [SocketService],
  templateUrl: './conversation.component.html',
  styleUrl: './conversation.component.scss'
})
export class ConversationComponent implements OnDestroy {
  @ViewChild('chatContent') chatContent!: ElementRef;
  @Input() set senderData(data: ChatDto | null) {
    if (!data) {return}
    this.socketService.disconnectSocket(false);
    this.senderInfo = data;
    this.chatId = data.id;
    this.socketService.startConversation(this.chatId);
    this.messages = [];
    this.page = 1;
    this.scrollTop = 0;
    this.totalPages = 1;
    this.totalElements = 0;
    this.loading = false;
    this.getMessages(false, false);
    this.loadChatMessages = true;
    this.senderInfo.unreadMessagesCount = 0;
    if (this.isMobile) {
      this.globalSubscriptionService.sendMessage('conversation',this.senderInfo);
    }
    this.loadingForConversationAppear();
  }

  form = new UntypedFormGroup({
    message: new UntypedFormControl('',[Validators.required]),
  })
  chatId!: string | number | null;
  messages: Array<MessagesInterface> = [];
  totalElements = 0;
  messageSubscription!: Subscription;
  senderInfo!: ChatDto | null;
  userInfo!:UsersInterface | null
  page = 1;
  scrollTop = 0;
  totalPages = 1;
  loading = false;
  sendLoading = false;
  isMobile = false;
  disableScroll = false;
  formattedMessages: Array<{ type: 'date' | 'message'; content: any }> = [];
  selectedFiles: File[] = []; // Define the array
  loadChatMessages = false;

  constructor(
    private requestService: RequestMethodsService,
    private socketService: SocketService,
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private browserService: BrowserService,
    private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    if (this.isMobile) {
      this.chatId = this.activeRoute.snapshot.params['id'];
      this.getSenderData()
      this.getMessages(false,false)
    }
    this.userInfo = this.authService.getUserInfo();
    this.messageSubscription = this.socketService.subjectForMessaging.subscribe((message: any) => {
      if (message.seen) {
        this.messages.forEach(mes => {
          mes.read = true
        })
      } else {
        this.messages.push(message)
      }
      this.processMessages();
      this.hasDifferenceGreaterOrEqual15Minutes(this.messages);
      this.scrollDown();
    })
  }


  loadingForConversationAppear(): void {
    setTimeout(() => {
      this.loadChatMessages = false;
    }, 1000)
  }

  scrollDown(): void {
    setTimeout(() => {
      this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
      this.sendLoading = false;
    },100)
  }

  getSenderData(): void {
    this.requestService.get(`${EndpointConstant.user}/${EndpointConstant.chat}/${this.chatId}`).subscribe((res: ChatDto) => {
      res.unreadMessagesCount = 0;
      this.senderData = res;
    })
  }

  askQuestion(): void {
    if (this.senderInfo?.archived) {
      this.senderInfo.archived = false;
    }
  }

  getMessages(scroll: boolean = false,loading = true): void {
    if (this.loading || (this.page > this.totalPages)) { return; }
    let elementTo = null;
    if (scroll && this.scrollTop === 0) {
      elementTo = document.getElementById('message_' + this.formattedMessages[0].content?.id);
    }

    this.loading = loading;
    this.requestService.get(`${EndpointConstant.user}/${EndpointConstant.messages}/${this.chatId}?page=${this.page}&size=20`)
      .pipe(tap(() => this.loading = false))
      .subscribe((res: MessagesPaginationInterface) => {
        this.page++;
        this.totalPages = res.totalPages;
        this.totalElements = res.totalElements;
        this.messages = [...res.content.reverse(),...this.messages];
        this.hasDifferenceGreaterOrEqual15Minutes(this.messages);
        this.processMessages();
        if (!scroll) {
          this.scrollDown();
        } else {
          if (elementTo && scroll && this.scrollTop == 0) {
            setTimeout(() => {
              this.chatContent.nativeElement.scrollTop = 300;
            },100)
          }
        }
    })

  }

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      // For multiple files, store them in an array
      this.selectedFiles = Array.from(input.files); // Convert to array
      this.sendMessages('click',this.selectedFiles)
      // If only one file is allowed, you could do: this.selectedFile = input.files[0];
    }

  }

  hasDifferenceGreaterOrEqual15Minutes(messages: any): void {
    messages.forEach((item:MessagesInterface, index: any) => {
      if (item?.mine === messages[index + 1]?.mine) {
        const diffInMilliseconds = Math.abs(
          new Date(item?.createdAt).getTime() - new Date(messages[index + 1]?.createdAt).getTime()
        );
        const diffInMinutes = diffInMilliseconds / (1000 * 60);
        item.hiddenImg = diffInMinutes <= 10;
      }
    })
  }

  clearChat(): void {
    this.senderInfo = null;
    this.formattedMessages = [];
  }

  processMessages() {
    this.formattedMessages = [];
    let lastDate = '';

    this.messages.forEach((msg) => {
      const messageDate = new Date(msg.createdAt).toLocaleDateString();

      if (messageDate !== lastDate) {
        this.formattedMessages.push({ type: 'date', content: messageDate });
        lastDate = messageDate;
      }

      this.formattedMessages.push({ type: 'message', content: msg });
    });

  }


  processMessagesForSingleMessage(message: MessagesInterface): void {
    const messageDate = new Date(message.createdAt).toLocaleDateString();
    let lastDate = new Date(this.messages[this.messages.length - 2].createdAt).toLocaleDateString();

    if (messageDate !== lastDate) {
      this.formattedMessages.push({ type: 'date', content: messageDate });
    }

    this.formattedMessages.push({ type: 'message', content: message });
  }

  sendMessages(event?: any, selectedFiles?: Array<File>): void {
    let messageText = this.form.get('message')?.value?.trim() || '';

    if (!selectedFiles?.length && !messageText.length) {
      return;
    }

    const formData = new FormData();
    formData.append("chatId", String(this.chatId));
    formData.append("text", messageText);

    if (selectedFiles && selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        formData.append("resources[]", file); // Original
      });
    } else {
      console.log("No files selected.");
    }

    if (event.keyCode === 13 || event === 'click') {
      this.sendLoading = true;
      this.requestService.post(`${EndpointConstant.chat}/${EndpointConstant.messages}/${EndpointConstant.send}`, formData)
        .subscribe((res: MessagesInterface) => {
          this.messages.push(res);
          this.processMessagesForSingleMessage(res);
          this.hasDifferenceGreaterOrEqual15Minutes(this.messages);
          this.form.reset();
          this.selectedFiles = []; // Clear files AFTER sending
          this.scrollDown();
          this.sendLoading = false;
        }, () => {
          this.sendLoading = false;
          const message = {
            id: 1,
            text: messageText,
            createdAt: new Date().toString(),
            mine: true,
            read: true,
            delivered: true,
            resendWithWarning: true
          };
          this.messages.push(message);

          this.hasDifferenceGreaterOrEqual15Minutes(this.messages);
          this.processMessagesForSingleMessage(message);

          setTimeout(() => {
            this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
          }, 100);

          this.form.reset();
          this.selectedFiles = []; // Clear files AFTER sending
        });
    }
  }


  onMessagesSectionScroll(event: any): void {
    if (this.sendLoading) {
      return;
    }
    if (!event.target && this.page == 1) { return; }
    const element = event.target;
    if ((element.scrollTop === 0) && (this.messages.length / (20 * (this.page - 1))) >= 1) {
      if (this.loading) { return; }
      // this.scrollTop = element.scrollTop + 300;
      this.getMessages(true);
    }
  }

  sendMessagesAgain(message: string,id: number): void {
    this.sendMessages('click')
  }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();
    this.socketService.disconnectSocket();
    if (this.isMobile) {
      this.globalSubscriptionService.sendMessage('updateUnreadMessagesCount','')
    }
  }

}
