import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
//constants
import {CityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import { TranslateModule } from '@ngx-translate/core';
import {
  ShowDefaultAddressToChangeComponent
} from "./show-default-address-to-change/show-default-address-to-change.component";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-top-navbar',
    templateUrl: './top-navbar.component.html',
    styleUrls: ['./top-navbar.component.scss'],
    standalone: true,
  imports: [TranslateModule, ShowDefaultAddressToChangeComponent, NgIf]
})
export class TopNavbarComponent {
  @Input() set selectedData(data: { address: FavoriteAddressInterface } | { city: CityInterface }) {
    if (!data) { return; }
    this.selectedAddressName = 'address' in data ? data.address.fullAddress : data.city.name
  };
  @Input() defaultAddressIsSelected!: boolean;
  @Output() selectAddressToDeliverEvent = new EventEmitter<boolean>();
  selectedAddressName!: string;

  constructor() {}

  navigateToSellerLoginPage(): void {
    window.open('https://admin.click-market.ru/seller/login', '_blank');
  }

  handleDialogClose(openAddressSelection: boolean): void {
    this.selectAddressToDeliverEvent.emit(openAddressSelection);
  }
}
