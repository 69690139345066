<div class="privacy_policy_container w_100 h_100 lamptop_padding" [ngClass]="{'mt_35' : !isMobile}">
  <div class="mobile_title">
    {{'my_account.terms_and_agreements' | translate}}
  </div>
  <app-navigation *ngIf="!isMobile" [navigationData]="[{ label: 'auth.privacy.' + title}]"></app-navigation>
  <div class="privacy_policy_container--body mb_32" [ngClass]="{'pt_50': !isMobile}">
    <ng-container *ngIf="!isMobile">
    <span class="privacy_policy_container--body--title text_title_middle pb_35">
     {{'auth.privacy.' + title | translate}}
    </span>
      <div></div>

      <div class="privacy_policy_container--body--info rules">
        <ng-container *ngIf="title=='processing_policy_data_recovery';else secondComponent">
          <app-personal-data></app-personal-data>
        </ng-container>
        <ng-template #secondComponent>
          <app-terms-of-use></app-terms-of-use>
        </ng-template>
      </div>
    </ng-container>
    <div class="position_relative">
      <div class="privacy_policy_container--body--info">
      <span class="bold_700 text_title" *ngIf="!isMobile">
        {{'auth.privacy.whole' | translate }} {{'auth.privacy.documentation' | translate | lowercase }}
      </span>

        <div class="privacy_policy_container--body--info--whole_documentation_switch text_normal_sub">

          <div class="first pointer mb_16 flex_between_align_center" [ngClass]="{'active_class':isActive=='first', 'mt_40': !isMobile}"
               (click)="changeOrderType('first')">
            <span>{{'auth.privacy.processing_policy_data_recovery' | translate }}</span>
            <img src="assets/images/mobile_icons/arrow_left.svg">
          </div>
          <div class="pointer second flex_between_align_center" [ngClass]="{'active_class':isActive=='second'}"
               (click)="changeOrderType('second')">
            <span>
              {{'auth.privacy.terms_of_use_of_the_site' | translate }}
            </span>
            <img src="assets/images/mobile_icons/arrow_left.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
