<div class="content position_relative">
  <div class="chat_content" (scroll)="onMessagesSectionScroll($event)"  #chatContent
       [ngClass]="{
       'overflow_hidden': disableScroll,
       height_of_conversation_to_achieve: senderInfo?.archived && !isMobile,
       chat_content_whit_archive: senderInfo?.archived && isMobile
       }">
    <img class="chat_loader" src="assets/images/icons/chat/conversation_loader.svg" [hidden]="!loading">
    <div class="description" [ngClass]="{'hidden' : messages.length !== totalElements}">
      {{'chat.single_chat_info' | translate}}
    </div>
    <div class="chat-container" *ngIf="formattedMessages.length" >
      <div *ngFor="let item of formattedMessages, let i = index"  [id]="'message_' + item.content.id">
        <!-- Date Separator -->
        <div *ngIf="item.type === 'date'" class="flex_center_align_center">
          <div class="date-separator">
            <span>{{ item.content | changeDateSeparatorFormatPipe}}</span>
          </div>
        </div>

        <!-- Chat Message -->
        <div *ngIf="item.type === 'message'"  class="message user-message" [ngClass]="{'operator-message': !item.content.mine}">
          <img *ngIf="!item.content.mine" [ngClass]="{hiddenImg: item.content.hiddenImg}" [src]="!senderInfo?.orderId? 'assets/images/icons/chat/admin_logo_with_chat.svg' : (senderInfo?.senderImageUrl || 'assets/images/icons/chat/sender_empty.svg')" alt="User Avatar" class="avatar mr_8" />
          <div class="w_60_max" [ngClass]="{'flex_column flex_align_end' : item.content.mine}">
            <div class="message-content"
                 [ngClass]="{'operator-message_borders_radius' : !item.content.mine,
                  'user_message_borders_radius' : item.content.mine,
                   'resources_state' : item.content.resources && !item.content.text}"
            >
              <div class="message-content--text" *ngIf="item.content.text">{{ item.content.text }}</div>
              <app-resources [resources]="item.content.resources"></app-resources>
              <div class="flex_end_align_center" [ngClass]="{mes_time_whit_resource : item.content?.resources?.length}" >
                <span class="time" [ngClass]="{'text_color_white' : item.content.resources?.length,'operator-message_time_color': !item.content.resources?.length &&  !item.content.mine}">{{ item.content.createdAt | toLocalDate : 'HH:mm' }}</span>
                <img class="ml_4" *ngIf="item.content.mine" [src]="!item.content?.resendWithWarning && item.content.mine && !item.content.read? 'assets/images/icons/chat/tick.svg' : 'assets/images/icons/chat/message_read_icon.svg'">
                <img class="ml_5" *ngIf="item.content?.resendWithWarning" src="assets/images/icons/chat/message_warning.svg">
              </div>
            </div>
            <div *ngIf="item.content?.resendWithWarning" class="warning_message mt_4 pointer" (click)="sendMessagesAgain(item.content.text,item.content.id)">
              Отправить повторно
            </div>
          </div>

          <img *ngIf="item.content.mine" [ngClass]="{hiddenImg: item.content.hiddenImg}" [src]="userInfo?.imageUrl || 'assets/images/icons/user-profile.svg'" alt="User Avatar" class="avatar ml_8" />
        </div>
      </div>
      </div>
</div>
  <div class="message_send_container" [ngClass]="{hidden: !senderInfo}" [formGroup]="form">
    <div *ngIf="!senderInfo?.archived" class="flex_between_align_center">
      <div class="flex_between_align_center message_input">
        <textarea cdkTextareaAutosize  formControlName="message" (keyup)="sendMessages($event)" placeholder="Ваше сообщение"></textarea>
        <input type="file" [multiple]="true" #fileInput (change)="onFilesSelected($event)" accept="image/*" hidden>
        <img class="pointer" src="assets/images/icons/chat/paperclip.svg" (click)="fileInput.click()">
      </div>
      <div class="next_button pointer" [ngClass]="{send_button_loading_state : sendLoading}" (click)="sendMessages('click')">
        <img src="assets/images/icons/chat/arrow_right.svg">
      </div>
    </div>
    <div *ngIf="senderInfo?.archived">
      <div class="chat_in_archive_text">
        Чат находится в архиве
      </div>
      <div class="return_chat_text mt_4">
        Вы можете вернуть чат из архива, задав вопрос
      </div>
      <button class="ask_question_button mt-16 pointer" (click)="askQuestion()">
        Задать вопрос
      </button>
    </div>
  </div>

<!--  Loading chat messages-->
  <div *ngIf="loadChatMessages" class="loading">
    <app-loading [loading]="loadChatMessages"></app-loading>
  </div>
</div>
