import {Component} from '@angular/core';

@Component({
  selector: 'app-banner',
  standalone: true,
  imports: [],
  template: `
    <div class="banner_container">
              <img src="assets/images/icons/banner/up_banner_10.jpg">
    </div>
  `,
  styles: [`
    .banner_container {
      height: 48px;
      overflow: hidden;
      background: linear-gradient(90deg, #FF9696 0%, rgba(252, 126, 150, 0.00) 67.32%), rgba(0, 0, 0, 0.20);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  `]
})
export class BannerComponent {

}
