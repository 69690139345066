import {Component, Inject, ViewChild} from '@angular/core';
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {GlobalModule} from "../global.module";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgIf} from "@angular/common";
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
// Components
import {MessagesComponent} from "./messages/messages.component";
import {ConversationComponent} from "./conversation/conversation.component";
// Constants
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {ChatDto} from "@interfaces/chat/chat.interfaces";

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    GlobalModule,
    MessagesComponent,
    ConversationComponent,
    NgIf
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  @ViewChild('conversationComponent') conversationComponent!: ConversationComponent
  messageId!: number;
  senderData!: ChatDto | null;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private requestService: RequestMethodsService,
    @Inject(MAT_DIALOG_DATA) public id: number,
  ) {
    if (id) {
      this.getUserInfo(id)
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  updateConversationPage():void {
    this.conversationComponent.clearChat();
    this.senderData = null;
  }

  getUserInfo(id: number): void {
    this.messageId = id;
    this.requestService.get(`${EndpointConstant.user}/${EndpointConstant.chat}/${id}`).subscribe((res: ChatDto) => {
      res.unreadMessagesCount = 0;
      this.senderData = res;
    })
  }
}
