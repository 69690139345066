import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => console.log('Service Worker registered:', registration))
    .catch((err) => console.error('Service Worker registration failed:', err));
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
