import {Component, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-resources',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgForOf
  ],
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent {
@Input()  resources?: Array<{
  name: string,
  resourceType: string,
  resourceUrl: string
}>

  get hasTwoImages(): boolean {
    return this.resources?.length === 2;
  }

  get hasOddLength(): boolean {
    return (this.resources?.length ?? 0) % 2 !== 0;
  }

  get hasFourImages(): boolean {
  return (this.resources?.length ?? 0) == 4;
  }

  get hasEvenLength(): boolean {
    return ((this.resources?.length ?? 0) > 4 && (this.resources?.length ?? 0) % 2 === 0);
  }

  openImgNewBlank(url: string): void {
    window.open(url, '_blank');
  }
}
