<div class="flex h_100">
  <div>
    <div class="title">
      {{'my_account.chats' | translate}}
    </div>
    <app-messages [activeChat]="id" (chatId)="getUserInfo($event)" (updateConversation)="updateConversationPage()"></app-messages>
  </div>
  <div class="w_100 chat">
    <div class="flex_between header">
      <div class="flex_align_center acc_info">
        <img class="logo" *ngIf="senderData" [src]="!senderData?.orderId? 'assets/images/icons/chat/admin_logo_with_chat.svg' : senderData?.senderImageUrl || 'assets/images/icons/chat/sender_empty.svg'">
        <div class="ml_12" *ngIf="senderData">
          <div class="shop_name">
            {{senderData?.orderId === null? 'Click Market' : senderData?.senderName}}
          </div>
          <div class="order_number">
            {{senderData?.orderId === null? 'Техподдержка' : 'Заказ №' + senderData.orderId}}
          </div>
        </div>
      </div>
      <app-clear-button (closeButton)="close()"></app-clear-button>
    </div>
    <app-conversation [senderData]="senderData" #conversationComponent></app-conversation>
  </div>
</div>
