<div class="popular_products_container laptop_margin mt_60 position_relative" *ngIf="popularProducts?.length">
  <div class="title_container text_title_36 mb_32">
    {{isMobile ? ('product.catalog.other_products' | translate) : ('product.catalog.popular_products' | translate)}}
  </div>
<!--  <app-custom-slider>-->
  <div class="flex_align_center popular_products_cards">
    <div *ngFor="let item of popularProducts" class="h_100 items">
      <app-product-card [productCard]="item"
                        [numOfColumns]="3"
                        [type]="productCardTypeEnum.POPULAR"
                        [componentType]="componentType || productCardTypeEnum.POPULAR"
      ></app-product-card>
    </div>
  </div>
<!--  </app-custom-slider>-->
</div>
