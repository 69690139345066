<div class="messages mt_20">
  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange()">
    <mat-tab label="{{'chat.recent' | translate}}">
      <div class="recent mt_20" (scroll)="onMessagesSectionScroll($event)" #messagesDiv>
        <div [id]="message.id" class="recent--message flex_align_center pointer"
             *ngFor="let message of messages"
             [ngClass]="{'active_message' : (selectedMessages === message.id)}"
             (click)="openChat(message)">
          <img class="account_img" [src]="message.orderId === null? 'assets/images/icons/chat/click_logo.svg' : (message.senderImageUrl || 'assets/images/icons/chat/sender_empty.svg')" >
          <div class="account_info ml_16 w_100">
            <div class="flex_between_align_center">
              <div class="flex_align_center">
                <div class="account_name">
                  {{message.orderId === null? 'Click Market' : message.senderName}}
                </div>
                <img class="ml_5" *ngIf="message.orderId === null" src="assets/images/icons/chat/union.svg">
              </div>
              <div class="time">
                {{message.lastMessageTime  | toLocalDate : 'HH:mm'}}
              </div>
            </div>
            <div class="account_rol">
              {{ message.orderId === null? 'Техподдержка' : 'Заказ №' + message.orderId}}
            </div>
            <div class="flex_between_align_center">
              <div class="mt_8 message">
                {{message.lastMessage || ('chat.custom_last_messages' | translate)}}
              </div>
              <div class="message_count" *ngIf="message.unreadMessagesCount">
                {{message.unreadMessagesCount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'chat.archive' | translate}}">
      <div class="recent mt_20" (scroll)="onMessagesSectionScroll($event)" #archiveMessagesDiv >
        <div class="recent--message pointer flex_align_center"
             *ngFor="let archive of archiveMessages"
             (click)="openChat(archive)"
             [ngClass]="{'active_message' : selectedMessages === archive.id}"
        >
          <img class="account_img" [src]="archive.senderImageUrl">
          <div class="account_info ml_16 w_100">
            <div class="flex_between_align_center">
              <div class="flex_align_center">
                <div class="account_name">
                  {{archive.senderName}}
                </div>
              </div>
              <div class="time">
                {{archive.lastMessageTime | toLocalDate : 'HH:mm'}}
              </div>
            </div>
            <div class="account_rol">
              {{'Заказ №' + archive.orderId}}
            </div>
            <div class="flex_between_align_center">
              <div class="mt_8 message">
                {{archive.lastMessage || ('chat.custom_last_messages' | translate)}}
              </div>
              <div class="message_count" *ngIf="archive.unreadMessagesCount">
                {{archive.unreadMessagesCount}}
              </div>
            </div>
          </div>
        </div>
        <div class="empty w-100" *ngIf="emptyPage">
          <div class="empty--title">
            {{'chat.empty_title' | translate}}
          </div>
          <div class="empty--description mt_12">
            {{'chat.empty_description' | translate}}
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <app-loading [loading]="loading"></app-loading>
</div>
