import {
  Component,
  Inject,
  makeStateKey,
  OnDestroy,
  OnInit,
  PLATFORM_ID, Renderer2,
  TransferState
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {TranslateModule} from '@ngx-translate/core';
import {
  NgIf,
  NgClass,
  NgFor,
  DecimalPipe,
  TitleCasePipe,
  NgStyle,
  isPlatformServer,
  isPlatformBrowser, DOCUMENT
} from '@angular/common';
import {MillisecondsToMinutesPipe} from "../../../pipes/date-methodes/milliseconds-to-minutes.pipe";
import {Clipboard} from "@angular/cdk/clipboard";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import {Meta, Title} from "@angular/platform-browser";
// Constants
import {ProductSingleInterface} from "@interfaces/catalog/products.interface";
import {UpdateSubscriptionTypesEnum} from "@interfaces/orders/enums/update-subscription-types.enum";
import {AddAndReduceStatusEnum} from "@interfaces/components/enums/add-and-reduce-status.enum";
import {AddToFavoriteTypeEnum} from "@interfaces/components/enums/add-to-favorite-status.enum";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";
import {SwiperTypeEnum} from "@interfaces/components/enums/swiper-type.enum";
import {CityOrAddressSelectionInterface} from "@interfaces/catalog/filtration-default-state.const";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {NavigationInterface} from "@interfaces/components/navigation.interface";
import {CardEnum} from "@interfaces/catalog/product-card.enum";
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {AuthService} from "@services/auth/auth.service";
import {GlobalSubscriptionService} from "@services/subscriptions/global-subscription.service";
import {BasketService} from "@services/basket/basket.service";
import {ComponentService} from "@services/components/component.service";
import {SwiperService} from "@services/components/swiper.service";
import {BrowserService} from "@services/components/browser.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {ShowMessageService} from "@services/messages/show-message.service";
import {CityService} from "@services/city/city.service";
//Components
import {AddAndReduceComponent} from '../add-and-reduce/add-and-reduce.component';
import {AddToFavoritesButtonComponent} from '../../global/add-to-favorites-button/add-to-favorites-button.component';
import {ProductGalleryComponent} from '../product-gallery/product-gallery.component';
import {VerticalSwiperComponent} from './vertical-swiper/vertical-swiper.component';
import {PopularProductsComponent} from "../new-catalog/popular-products/popular-products.component";
import {StarRateComponent} from "../../global/star-rate/star-rate.component";
import {CustomSliderComponent} from "../../global/custom-slider/custom-slider.component";
import {NavigationComponent} from "../../global/navigation/navigation.component";
import {BonusDisplayComponent} from "../bonus-display/bonus-display.component";
//Pipes
import {PriceSpacesPipe} from "../../../pipes/string-methods/price-spaces.pipe";

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    VerticalSwiperComponent,
    ProductGalleryComponent,
    AddToFavoritesButtonComponent,
    AddAndReduceComponent,
    NgFor,
    TranslateModule,
    DecimalPipe,
    TitleCasePipe,
    MillisecondsToMinutesPipe,
    PopularProductsComponent,
    StarRateComponent,
    NgStyle,
    PriceSpacesPipe,
    NavigationComponent,
    CustomSliderComponent,
    BonusDisplayComponent
  ]
})
export class SinglePageComponent implements OnInit, OnDestroy {
  productId!: number;
  openCount = false;
  data!: ProductSingleInterface;
  pending = false;
  addAndReduceTypeEnums = AddAndReduceStatusEnum;
  isMobile!: boolean;
  addToFavoriteTypeEnum = AddToFavoriteTypeEnum;
  swiperTypeEnum: SwiperTypeEnum = SwiperTypeEnum.SINGLE_PAGE
  cityOrAddress!: CityOrAddressSelectionInterface;
  stateTransformKey = makeStateKey<{ data: ProductSingleInterface | null }>("SinglePageData");
  subscription!: Subscription;
  navigationData: NavigationInterface[] = [];
  type!: CardEnum;
  isBrowser = false;

  constructor(
    private service: RequestMethodsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private globalSubscriptionService: GlobalSubscriptionService,
    private router: Router,
    private basketService: BasketService,
    private componentService: ComponentService,
    private swiperService: SwiperService,
    private confirmationDialogService: ConfirmationDialogService,
    private browserService: BrowserService,
    private clipboard: Clipboard,
    private showMessageService: ShowMessageService,
    private cityService: CityService,
    private transferState: TransferState,
    private meta: Meta,
    private title: Title,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    const cityOrAddressObject = this.cityService.getObjectCityOrAddress();
    if (!cityOrAddressObject) {
      return;
    }
    this.cityOrAddress = cityOrAddressObject;
    this.type = CardEnum[(this.route.snapshot.queryParamMap.get('type') || '') as keyof typeof CardEnum] || CardEnum.POPULAR;
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.subscription = this.globalSubscriptionService.getMessage()
        .subscribe((data: { type: string, message: boolean }) => {
          if (data.type === UpdateSubscriptionTypesEnum.UPDATE_CATALOG) {
            this.getSinglePage();
          }
        })
    }

  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      const params = (this.activatedRoute.snapshot.paramMap.get('id') || '').split('-');
      this.productId = +params[params.length - 1];
      if (!this.productId) { return; }
      this.getSinglePage();
    } else if (this.isBrowser) {
      const data: {data: ProductSingleInterface | null} = this.transferState.get(this.stateTransformKey, {data: null});
      if (data.data) {
        this.data = data.data;
        this.getSectionNameAndMakeUrl(this.data.section);
        setTimeout(() => {
          this.swiperService.makeHorizontalSwipe(SwiperTypeEnum.SINGLE_PAGE, true);
        }, 2000)
      }
    }
  }

  updateMetaTags(): void {
   if (!this.data.metaTitle || !this.data.metaDescription) {
     const cityName = this.cityService.getCityName();
     this.title.setTitle(
       `${this.data.title} купить с доставкой по городу ${cityName} по цене
      ${this.data.price} в цветочном маркетплейсе Click-market`
     );

     this.meta.updateTag({
       name: 'description',
       content: `${this.data.title} заказать по цене ${this.data.price}.
       Большой выбор букетов, корзин и композиций с цветами с доставкой по
       ${cityName} на маркетплейсе Click-market.`
     });
   } else {
     this.title.setTitle(
       this.data.metaTitle
     );

     this.meta.updateTag({
       name: 'description',
       content: this.data.metaDescription
     });
   }

    const schemaOrgData = {
      "@context": "https://schema.org",
      "@type": "Product",
      "url": `https://click-market.ru/${this.router.url}`,
      "name": this.data.title,
      "description": this.data.description,
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": this.data.averageRate?.toFixed(1),
        "reviewCount": this.data.rateCount
      },
      "image": this.data.resourceUrls[0].resourceUrl,
      "offers": {
        "@type": "Offer",
        "price": this.data.price?.toFixed(2),
        "priceCurrency": "RU",
        "availability": "https://schema.org/InStock",
        "url": `https://click-market.ru/${this.router.url}`,
      }
    };
    const scriptTag = this.renderer.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(schemaOrgData);
    this.renderer.appendChild(this.document.head, scriptTag);

    // Open Graph
    this.meta.updateTag({
      name: 'og:logo',
      content: 'https://click-market.ru/assets/images/ic_logo.png',
    });
    this.meta.updateTag({
      name: 'og:title',
      content: this.data.title,
    });
    this.meta.updateTag({
      name: 'og:description',
      content: this.data.description,
    });

    this.meta.updateTag({
      name: 'og:image',
      content: this.data.resourceUrls[0].resourceUrl,
    });
    this.meta.updateTag({
      name: 'og:url',
      content: `https://click-market.ru/${this.router.url}`,
    });
    this.meta.updateTag({
      name: 'og:type',
      content: 'website',
    });
  }

  getSinglePage(): void {
    if (!this.productId) {
      return;
    }
    this.service.post(`${EndpointConstant.products}/${this.productId}`, this.cityOrAddress).subscribe((res: ProductSingleInterface) => {
      this.data = res;
      this.getSectionNameAndMakeUrl(this.data.section);
      this.transferState.set(this.stateTransformKey, {data: res}); // SET data from Server Side to rerender it in client side
      this.updateMetaTags();
    })
  }


  getSectionNameAndMakeUrl(section: { id: number, name: string }): void {
    const sectionName = section ? CyrillicToTranslit().transform(section.name, "-").toLowerCase() : '';
    const shopName = CyrillicToTranslit().transform(this.data.shop.shopName, "-").toLowerCase();
    const sectionCatalogUrl = `products/${sectionName}?sectionId=${section.id}`;
    let shopPageUrl = `/shop/${shopName}/${this.data.shop.id}`
    switch (this.type) {
      case CardEnum.POPULAR:
        this.navigationData.push({label: this.data.title});
        break;
      case CardEnum.SHOP_SECTION:
        shopPageUrl += `?sectionId=${section.id}`;
        this.navigationData.push(
          {label: this.data.section.name, url: sectionCatalogUrl},
          {label: this.data.shop.shopName, url: shopPageUrl},
          {label: this.data.title}
        );
        break;
      case CardEnum.SHOP:
        this.navigationData.push(
          {label: this.data.shop.shopName, url: shopPageUrl},
          {label: this.data.title}
        );
        break;
      default:
        this.navigationData.push(
          {label: this.data.section.name, url: sectionCatalogUrl},
          {label: this.data.title}
        );
        break;
    }
  }

  goToCart() {
    this.router.navigate([this.isMobile ?'basket' : 'order-creation' ])
  }

  addToBasket(event: Event): void {
    event.stopPropagation();
    if (!this.authService.checkTokenAndRedirectToAuth() || this.pending) {
      return;
    }

    if (!this.basketService.checkBasketForShop(this.data.shop.id)) {
      this.clearBasketForShop();
      return;
    }

    this.addToBasketMethod();
  }

  addToBasketMethod(): void {
    this.pending = true;
    this.basketService.addBasketItems(this.data.id, this.data.shop.id).subscribe(() => {
      if (this.isMobile) {
        this.getSinglePage();
      }
      this.globalSubscriptionService.sendMessage(UpdateSubscriptionTypesEnum.UPDATE_BASKET);
      this.openCount = true;
      this.data.countInBasket = 1;
      this.pending = false;
    }, () => {
      this.pending = false;
    })
  }

  filterProductsByShop(): void {
    const name = CyrillicToTranslit().transform(this.data.shop.shopName, "-").toLowerCase();
    window.location.href = `/shop/${name}/${this.data.shop.id}`;
  }

  clearBasketForShop(): void {
    this.confirmationDialogService.openConfirmationDialog(
      {
        title: 'notification.clear_bucket',
        content: 'notification.need_to_delete_all_items',
        firstButton: 'notification.back',
        secondButton: 'notification.clear',
      }).afterClosed().subscribe((res: ConfirmationActionEnum) => {
      if (res === ConfirmationActionEnum.APPROVE || !res) {
        return;
      }
      this.basketService.clearBasket().subscribe(() => {
        this.addToBasketMethod();
      });
    })
  }

  copyPathForShare(): void {
    this.clipboard.copy(`${window.location.href}`);
    this.showMessageService.showMessageByVariable('copied', 'success');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
