import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'millisecondsToMinutes',
  standalone: true
})
export class MillisecondsToMinutesPipe implements PipeTransform {
  transform(
    value: number,
    interval?: number
  ): string {
    if (!value) {
      return '';
    }
    let minutes = Math.floor(value / 60000);
    if (interval) {
      const deliveryInterval = (Math.floor((interval + value) / 60000));
      return minutes ? minutes + '-' + deliveryInterval : deliveryInterval + '' ;
    } else {
      return minutes + '';
    }
  }

}
